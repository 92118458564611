module directives {
    export module tariff {
        interface ITariffListScope extends ng.IScope {
            search();
            showDescription(selectedItem: interfaces.tariff.INationalMeasureCodeLookup);
            displayNotes(notes: string);
            nationalMeasureCode: string;
            description: string;
            dutyTypeCode: string;
            dutyTypeId: number;
            countryId: number;
            simulationDate: moment.Moment;
            showGrid: boolean;
            isLoading: boolean;
            exclusions: boolean;
            gvwTariffCodes: uiGrid.IGridOptions | any;
            wcoTradeToolsEnabled:boolean;

        }

        export class tariffListDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/tariff/tariffListView.html';
            scope = {
                nationalMeasureCode: "=",
                dutyTypeCode: "=",
                dutyTypeId: "=",
                countryId: "=",
                simulationDate: "=",
                exclusions: "=",
                description: "="
            }

            messages: interfaces.applicationcore.IMessage[];

            apiTariffCodes: uiGrid.IGridApi;

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService,
                private wcoTradeToolsService: interfaces.tariff.IWCOTradeToolsService,
            ) {
            }

            link = ($scope: ITariffListScope, $element: ng.IAugmentedJQuery, $state) => {

                $scope.wcoTradeToolsEnabled   = false;
                $scope.showGrid = true;

                $scope.showDescription = (selectedItem: interfaces.tariff.INationalMeasureCodeLookup) => {
                    this.showDescription(selectedItem);
                }

                $scope.displayNotes = (notes: string) => {
                    this.displayNotes(notes);
                }

                $scope.gvwTariffCodes = {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnMenus: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: false,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    onRegisterApi: (gridApi) => { this.apiTariffCodes = gridApi; },
                    columnDefs: [{
                        name: "SELECT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div ng-if="row.entity.DutyTypeCode">
                                <gts-tariff-detail   national-measure-code="row.entity.NationalMeasureCode" 
                                                    duty-type-code="row.entity.DutyTypeCode"
                                                    duty-type-id="row.entity.CodeTypeClassificationValueId"
                                                    country-id="row.entity.CountryId"
                                                    simulation-date="grid.appScope.simulationDate">
                                </gts-tariff-detail>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 37
                    }, {
                        name: "CODE",
                        displayName: "Code",
                        field: "FormattedCode",
                        enableFiltering: false,
                        width: 150
                    }, {
                        name: "ICON",
                        displayName: "P&R",
                        field: "PR",
                        cellTemplate: `
                            <div>
                                <center>
                                    <div ng-if="row.entity.PR === 0">
                                        <span class="fa fa-check" style="color: #bcd856;"></span>
                                    </div>
                                    <div ng-if="row.entity.PR === 1">
                                        <span class="fa fa-warning" style="color: #f57f20;"></span>
                                    </div>
                                    <div ng-if="row.entity.PR === 2">
                                        <span class="fa fa-warning" style="color: #cb2330;"></span>
                                    </div>
                                </center>
                            </div>`,
                        enableFiltering: false,
                        width: 45
                    }, {
                        name: "DESCRIPTION",
                        displayName: "Description",
                        field: "Description",
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-click="grid.appScope.showDescription(row.entity)" ng-bind-html="row.entity.Description"></div>`,
                        enableFiltering: false,
                        width: 600
                    }, {
                        name: "DUTY",
                        displayName: "Duty",
                        field: "DutyRate",
                        enableFiltering: false,
                        width: 150
                    }, {
                        name: "DutyTypeCode",
                        displayName: "Duty Type",
                        field: "DutyTypeCode",
                        enableFiltering: false,
                        width: 80
                    }, {
                        name: "StatisticalUnitCode",
                        displayName: "UoM",
                        field: "StatisticalUnitCode",
                        enableFiltering: false,
                        width: 50
                    }, {
                        name: "Notes",
                        displayName: "Legal Notes",
                        field: "Notes",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton">
                                <center>
                                    <button ng-if="row.entity.Notes" type="button" class="btn btn-info btn-sm" ng-click="grid.appScope.displayNotes(grid.getCellValue(row, col))">
                                        <span class="fa fa-sticky-note"></span>
                                    </button>
                                </center>
                            </div>`,
                        width: 51,
                        headerTooltip: "Legal Notes",
                        enableColumnResizing: true,
                        },   {
                        name: "WCONotes",
                        displayName: "Explanatory Notes",
                        field: "Notes",
                        enableFiltering: false,
                        enableColumnResizing: true,
                        cellTemplate: `
                        <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length <= 4 && row.entity.CodeTypeClassificationValueId === 163">
                            <center>
                                    <wco-trade-tool-notes  
                                    is-section = "row.entity.IsSection"
                                    section="row.entity.Section"
                                    hs-code="row.entity.NationalMeasureCode"
                                    year="2022"
                                    notes-type="1"
                                    wco-enabled="grid.appScope.wcoTradeToolsEnabled"
                                >
                                </wco-trade-tool-notes>
                            </center>
                        </div>`,
                        width: 51,
                        enableSorting: false,
                        headerTooltip: "Explanatory Notes"
                    },
                    {
                        name: "WCOClassNotes",
                        displayName: "Classification Opinion",
                        field: "Notes",
                        enableFiltering: false,
                        enableColumnResizing: true,
                        cellTemplate: `
                        <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length === 6 && row.entity.CodeTypeClassificationValueId === 163">
                            <center>
                                    <wco-trade-tool-notes  
                                    is-section = "row.entity.IsSection"
                                    section="row.entity.Section"
                                    hs-code="row.entity.NationalMeasureCode"
                                    year="2022"
                                    notes-type="3"
                                    wco-enabled="grid.appScope.wcoTradeToolsEnabled"
                                >
                                </wco-trade-tool-notes>
                            </center>
                        </div>`,
                        width: 51,
                        enableSorting: false,
                        headerTooltip: "Classification Opinion"
                    }]
                };

                $scope.search = () => {
                    this.search_click(
                        $scope.isLoading,
                        $scope.showGrid,
                        $scope.dutyTypeId,
                        $scope.countryId,
                        $scope.simulationDate,
                        $scope.nationalMeasureCode,
                        $scope.description,
                        $scope.exclusions,
                        $scope.gvwTariffCodes, $scope.dutyTypeCode, $scope);
                };

                $scope.search();
            }

            public search_click(isLoading: boolean, showGrid: boolean, selectedTariffId: number, countryId: number, simulationDate: moment.Moment,
                nationalMeasureCode: string, description: string,
                exclusions: boolean, gvwTariffCodes: uiGrid.IGridOptions, dutyTypeCode:string ,scope: ITariffListScope) {                
                scope.isLoading = true;
                scope.showGrid = true;
                this.hsCodeService.getSimplifiedExpertSearchResult().query({
                    type: selectedTariffId,
                    countryId: countryId,
                    simulationDate: simulationDate.format("YYYYMMDDTHH:mm"),
                    code: nationalMeasureCode,
                    description: description,
                    exclusions: exclusions,
                    dutyTypeId:0
                }, (resultList) => {

                    if (selectedTariffId != 163 && dutyTypeCode.length > 0) {
                        resultList = _.filter(resultList, (n: interfaces.tariff.INationalMeasureCodeLookup) => {
                            return (n.DutyTypeCode.toLocaleLowerCase().indexOf(dutyTypeCode.toLocaleLowerCase()) >= 0 || dutyTypeCode.length === 0);
                        });
                    }

                    this.wcoTradeToolsService.VerifyWCOTradeToolsEnabled().query({ }, (result: interfaces.tariff.VerifyTradeTools) => {
                        scope.wcoTradeToolsEnabled = result.isWCOTradeToolsEnabled;
                    });

                    gvwTariffCodes.data = resultList;
                    scope.showGrid = true;
                    scope.isLoading = false;
                }, (errorResponse) => {
                    scope.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                });
            }

            showDescription(selectedItem: interfaces.tariff.INationalMeasureCodeLookup) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Code: {{Code}}</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Description"></p>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Description = selectedItem.Description;
                        $scope.Code = selectedItem.NationalMeasureCode;
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            displayNotes(notes: string) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Notes</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Notes"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = notes;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, hsCodeService, $state,
                    bsLoadingOverlayService, menuService,wcoTradeToolsService) => new tariffListDirective(generalService, $uibModal, hsCodeService, $state, bsLoadingOverlayService, menuService,wcoTradeToolsService);
                directive.$inject = ["generalService", "$uibModal", "hsCodeService", "$state", "bsLoadingOverlayService", "menuService",  "wcoTradeToolsService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsTariffList", tariffListDirective.factory());
    }
}